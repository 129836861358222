import React from 'react'
import Style from './ProfileLayoutSidebarShimmer.module.scss'
const ProfileLayoutSidebarShimmer = () => {
  return (
    <div className={`pro-px-5 pro-pt-5 ${Style.shimmer_container}`}>
                  <div className="pro-d-flex pro-flex-column pro-w-100">
                  <div className="blink img" style={{'--blink-duration': '1s', '--blink-delay': '0s'}}></div>
                  <span className="blink pro-pb-1"></span>
                  </div>
                  <div className="pro-my-5 pro-pt-2">
                    <span className="shimmer letter"></span>
                    <span className="shimmer letter para"></span>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <div className="shimmer pro-py-4 pro-w-100 pro-rounded-3"></div>
                    </div>
                    
                  </div>

                  <div className="pro-d-flex pro-justify-between pro-pb-2 pro-pt-4">
                    <span className="shimmer letter pro-py-5"></span>
                    <span className="shimmer letter pro-py-5"></span>
                    <span className="shimmer letter pro-py-5"></span>
                  </div>

                  <div className="pro-py-5 shimmer pro-mt-4 pro-w-100">
                    <div className="shimmer"></div>
                  </div>

                  <div className="pro-pt-5 pro-mt-3">
                    <span className="shimmer letter para pro-mb-5"></span>
                    <span className="shimmer letter para pro-mb-4"></span>
                    <span className="shimmer letter para pro-mb-5"></span>
                    <span className="shimmer letter para pro-my-3"></span>
                    <span className="shimmer letter para"></span>
                    <div className="pro-d-flex pro-gap-6 pro-pt-4 pro-pb-5">
                      <span className="shimmer pro-px-5 pro-py-1"></span>
                      <span className="shimmer pro-px-6 pro-py-1"></span>
                    </div>
                    <div className="pro-d-flex pro-gap-4 pro-py-3">
                        <span className="shimmer pro-py-5 pro-w-100"></span>
                        <span className="shimmer pro-py-5 pro-px-6"></span>
                    </div>
                    <div className="shimmer letter pro-mt-4"></div>
                  </div>
                </div>
  )
}

export default ProfileLayoutSidebarShimmer