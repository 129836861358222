import { Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../../assets/Assets";
import Style from "./searchCard.module.scss";

const SearchCard = ({ data, lastElement, handleProfileRedirect }) => {
  return (
    <div
      className={`pro-d-flex ${Style.root}`}
      ref={lastElement || null}
      onClick={() => handleProfileRedirect(data?._id)}
    >
      <div className={Style.img_wrap}>
        <Image
          src={
            data?.profile_image ??
            (data?.gender === 2
              ? Assets.FEMALEPROFILE
              : Assets.MALEPROFILE)
          }
          width={60}
          height={60}
          alt={`img- ${data?.name ?? ""}`}
        />
      </div>
      <div className={Style.content}>
        <h6>{`${data?.name ?? ""} (${
          data?.age ?? ""
        })`}</h6>
        <ul className={`pro-d-flex ${Style.id_wrap}`}>
          <li>
            <span className="material-symbols-outlined">account_box</span>
            <span>{data?.profile_id ?? ""}</span>
          </li>
          <li>
            <span className="material-symbols-outlined">call</span>
            <span>{data?.phone_number ?? ""}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SearchCard;
