
import Style from "./overviewShimmer.module.scss";
import SimpleReportShimmer from "../SimpleReportShimmer";
const OverviewShimmer = () => {
  return (
    <div className={`${Style.shimmer_container}`}>
      <SimpleReportShimmer />
      <div className={`pro-w-100 ${Style.table_item}`}>
        <div className="shimmer pro-px-6 pro-py-2"></div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
      </div>
      
      <div className={`pro-w-100 ${Style.table_item}`}>
        <div className="shimmer pro-px-6 pro-py-2"></div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
      </div>
      <div className={`pro-w-100 ${Style.table_item}`}>
        <div className="shimmer pro-px-6 pro-py-2"></div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
          <div className={Style.tr_wrap}>
              <div className={Style.left_td_wrap}>
                <div className={`shimmer ${Style.left_td}`}></div>
              </div>
              <div className={Style.right_td_wrap}>
                <div className={`shimmer ${Style.right_td}`}></div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default OverviewShimmer;
