import { DataContainer } from '@wac-ui-dashboard/wac_component_library';
import Style from './simpleReportShimmer.module.scss';

const SimpleReportShimmer = () => {
  return (
      <div className="pro-mb-4">
        <DataContainer>
        <div className="row" style={{'--blink-duration' : '0.6s'}}>
          <div className={`col ${Style.item}`} style={{'--blink-delay' : '0s'}}>
            <div className="blink pro-px-6 pro-py-1 pro-mb-1"></div>
            <div className={`blink pro-rounded-3 ${Style.item_number}`}></div>
          </div>
          <div className={`col ${Style.item}`} style={{'--blink-delay' : '0.2s'}}>
            <div className="blink pro-px-6 pro-py-1 pro-mb-1"></div>
            <div className={`blink pro-rounded-3 ${Style.item_number}`}></div>
          </div>
          <div className={`col ${Style.item}`} style={{'--blink-delay' : '0.4s'}}>
            <div className="blink pro-px-6 pro-py-1 pro-mb-1"></div>
            <div className={`blink pro-rounded-3 ${Style.item_number}`}></div>
          </div>
          <div className={`col ${Style.item}`} style={{'--blink-delay' : '0.6s'}}>
            <div className="blink pro-px-6 pro-py-1 pro-mb-1"></div>
            <div className={`blink pro-rounded-3 ${Style.item_number}`}></div>
          </div>
      </div>
      </DataContainer>
      </div>
  );
};

export default SimpleReportShimmer;
