import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";

const Dashboard = () => {
  return (
    <CommonLayout dashboard={true}>
      <Outlet />
    </CommonLayout>
  );
};

export default Dashboard;
